import "core-js/modules/es.array.index-of";
import "core-js/modules/es.string.trim";

/**
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param str
 * @returns {boolean}
 */

export function validUsername(str) {
  return str.indexOf(str.trim()) >= 0;
}