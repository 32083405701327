var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isPC == true
    ? _c("div", { staticClass: "bg-color", attrs: { id: "app" } }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("div", { staticStyle: { width: "100%", position: "relative" } }, [
            _c("img", {
              staticStyle: { width: "100%" },
              attrs: { src: require("../../assets/img/banner3.png") }
            }),
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  top: "0",
                  width: "100%",
                  height: "100%"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "padding-top": "10px"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "58%",
                          height: "35%",
                          display: "flex",
                          "padding-left": "20px",
                          "justify-content": "space-between"
                        }
                      },
                      [
                        _vm._l(_vm.changeRank, function(item, index) {
                          return _c("div", { key: index }, [
                            _c("div", { staticClass: "bannerItem" }, [
                              _c(
                                "div",
                                {
                                  style:
                                    item.status == 1
                                      ? "font-size:16px;font-weight:bold"
                                      : "font-size:14px"
                                },
                                [_c("span", [_vm._v(_vm._s(item.name))])]
                              ),
                              item.status == 1
                                ? _c("img", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "7px",
                                      "margin-top": "10px"
                                    },
                                    attrs: {
                                      src: require("../../assets/img/hudu.png")
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        }),
                        _vm._m(0)
                      ],
                      2
                    )
                  ]
                )
              ]
            ),
            _vm._m(1),
            _c("div", { staticClass: "dateChange" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "94%",
                    "margin-left": "3%",
                    height: "100%",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeDate2("before")
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#999" },
                          attrs: { type: "text", icon: "el-icon-arrow-left" }
                        },
                        [_vm._v("前一天")]
                      )
                    ],
                    1
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.paramsConfig.recordDate))]),
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeDate2("after")
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#999" },
                          attrs: { type: "text" }
                        },
                        [
                          _vm._v("后一天"),
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon--right"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ])
        ]),
        _c("div", { staticStyle: { width: "100%" } }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "94%",
                "margin-left": "3%",
                display: "flex",
                "align-items": "center"
              },
              on: {
                click: function($event) {
                  return _vm.changeList()
                }
              }
            },
            [
              _c("img", {
                staticStyle: { width: "10px", height: "10px" },
                attrs: { src: require("../../assets/img/qiehuan.png") }
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    color: "#4C96FF",
                    "font-size": "12px"
                  }
                },
                [
                  _vm.changeListType == 1
                    ? _c("span", [_vm._v("切换图片版")])
                    : _c("span", [_vm._v("切换列表版")])
                ]
              )
            ]
          )
        ]),
        _vm.changeListType == 1
          ? _c("div", { staticStyle: { width: "100%" } }, [
              _c(
                "div",
                {
                  staticStyle: { width: "94%", "margin-left": "3%" },
                  on: { scroll: _vm.scrollEvent }
                },
                _vm._l(_vm.tableData, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          padding: "20px 0 10px 0",
                          "border-bottom": "1px solid #F3F3F3"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            item.sequence == 1
                              ? _c("img", {
                                  staticStyle: {
                                    width: "15px",
                                    height: "20px"
                                  },
                                  attrs: {
                                    src: require("../../assets/img/first.png")
                                  }
                                })
                              : _vm._e(),
                            item.sequence == 2
                              ? _c("img", {
                                  staticStyle: {
                                    width: "15px",
                                    height: "20px"
                                  },
                                  attrs: {
                                    src: require("../../assets/img/second.png")
                                  }
                                })
                              : _vm._e(),
                            item.sequence == 3
                              ? _c("img", {
                                  staticStyle: {
                                    width: "15px",
                                    height: "20px"
                                  },
                                  attrs: {
                                    src: require("../../assets/img/third.png")
                                  }
                                })
                              : _vm._e(),
                            item.sequence != 1 &&
                            item.sequence != 2 &&
                            item.sequence != 3
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#7A7E89" } },
                                  [_vm._v(_vm._s(item.sequence))]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "20px",
                                  "font-size": "14px",
                                  "font-weight": "bold",
                                  "padding-bottom": "10px"
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "40px",
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              _vm._l(item.typeStr, function(item2, index2) {
                                return _c("div", { key: index2 }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "background-color": "#E3EEFC",
                                        color: "#2489FF",
                                        "font-size": "12px",
                                        "margin-right": "5px",
                                        padding: "5px 10px",
                                        "border-radius": "5px"
                                      }
                                    },
                                    [_c("span", [_vm._v(_vm._s(item2))])]
                                  )
                                ])
                              }),
                              0
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "detailBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpDetail(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" 详情 ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "watchBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpLink(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "10px",
                                        height: "11px"
                                      },
                                      attrs: {
                                        src: require("../../assets/img/seeIcon.png")
                                      }
                                    }),
                                    _vm._m(2, true)
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                }),
                0
              ),
              _vm.tableData == ""
                ? _c(
                    "div",
                    {
                      staticClass: "no-coupons",
                      staticStyle: { overflow: "hidden" }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "margin-top": "50px",
                          width: "150px",
                          height: "150px"
                        },
                        attrs: { src: require("../../assets/img/7.png") }
                      }),
                      _c("span", { staticStyle: { "font-size": "15px" } }, [
                        _vm._v("数据正在更新~")
                      ])
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.changeListType == 2
          ? _c("div", { staticStyle: { width: "100%" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "94%",
                    "margin-left": "3%",
                    "padding-top": "10px"
                  }
                },
                _vm._l(_vm.tableData, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          padding: "20px 0 10px 0",
                          display: "flex"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100px",
                              height: "130px",
                              position: "relative"
                            }
                          },
                          [
                            _c("el-image", {
                              staticStyle: {
                                width: "100%",
                                height: "100%",
                                "border-radius": "10px"
                              },
                              attrs: { fit: "cover", src: item.poster + "!226" }
                            }),
                            item.sequence == 1
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      "background-color": "#F6E38B",
                                      "border-top-left-radius": "10px",
                                      "border-bottom-right-radius": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          "font-size": "12px",
                                          color: "#C88B44"
                                        }
                                      },
                                      [_vm._v(" 1 ")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.sequence == 2
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      "background-color": "#78CAFE",
                                      "border-top-left-radius": "10px",
                                      "border-bottom-right-radius": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          "font-size": "12px",
                                          color: "#2B63A6"
                                        }
                                      },
                                      [_vm._v(" 2 ")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.sequence == 3
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      "background-color": "#F5B36D",
                                      "border-top-left-radius": "10px",
                                      "border-bottom-right-radius": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          "font-size": "12px",
                                          color: "#B57B3E"
                                        }
                                      },
                                      [_vm._v(" 3 ")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            item.sequence != 1 &&
                            item.sequence != 2 &&
                            item.sequence != 3
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                      position: "absolute",
                                      top: "0",
                                      left: "0",
                                      "background-color": "#B2C7E4",
                                      "border-top-left-radius": "10px",
                                      "border-bottom-right-radius": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                          "font-size": "12px",
                                          color: "#536885"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.sequence) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "sub-detail",
                            staticStyle: { width: "80%" }
                          },
                          [
                            _c("div", { staticClass: "sub-title" }, [
                              _vm._v(" " + _vm._s(item.name) + " ")
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between",
                                  "padding-top": "10px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  _vm._l(item.typeStr, function(item2, index2) {
                                    return _c("div", { key: index2 }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "#E3EEFC",
                                            color: "#2489FF",
                                            "font-size": "12px",
                                            "margin-right": "5px",
                                            padding: "5px 10px",
                                            "border-radius": "5px"
                                          }
                                        },
                                        [_c("span", [_vm._v(_vm._s(item2))])]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "font-size": "12px",
                                  margin: "10px 0 0 0",
                                  height: "40px"
                                }
                              },
                              [_vm._v(" " + _vm._s(item.phrase) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "flex-end"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "detailBtn",
                                        on: {
                                          click: function($event) {
                                            return _vm.jumpDetail(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 详情 ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "watchBtn",
                                        on: {
                                          click: function($event) {
                                            return _vm.jumpLink(item)
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "10px",
                                            height: "11px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/seeIcon.png")
                                          }
                                        }),
                                        _vm._m(3, true)
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                }),
                0
              )
            ])
          : _vm._e(),
        _vm.changeListType == 2 && _vm.tableData == ""
          ? _c(
              "div",
              {
                staticClass: "no-coupons",
                staticStyle: { overflow: "hidden" }
              },
              [
                _c("img", {
                  staticStyle: {
                    "margin-top": "50px",
                    width: "150px",
                    height: "150px"
                  },
                  attrs: { src: require("../../assets/img/7.png") }
                }),
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v("数据正在更新~")
                ])
              ]
            )
          : _vm._e()
      ])
    : _c("div", { staticClass: "bg-color", attrs: { id: "app" } }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                position: "relative",
                padding: "0",
                margin: "0",
                "background-color": "#E4F1FF"
              }
            },
            [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: require("../../assets/img/pcBanner.png") }
              }),
              _vm._m(4),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    bottom: "7%",
                    width: "100%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "60%",
                        height: "100%",
                        "margin-left": "20%"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "45%",
                            display: "flex",
                            "align-items": "center"
                          }
                        },
                        _vm._l(_vm.changeRank, function(item, index) {
                          return _c("div", { key: index }, [
                            _c("div", { staticClass: "bannerItem" }, [
                              _c(
                                "div",
                                {
                                  style:
                                    item.status == 1
                                      ? "font-size:16px;font-weight:bold"
                                      : "font-size:14px"
                                },
                                [_c("span", [_vm._v(_vm._s(item.name))])]
                              ),
                              item.status == 1
                                ? _c("img", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "7px",
                                      "margin-top": "10px"
                                    },
                                    attrs: {
                                      src: require("../../assets/img/hudu.png")
                                    }
                                  })
                                : _vm._e()
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                height: "40px",
                "background-color": "#E4F1FF"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "60%",
                    height: "100%",
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                    "margin-left": "20%"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeDate2("before")
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#999" },
                          attrs: { type: "text", icon: "el-icon-arrow-left" }
                        },
                        [_vm._v("前一天")]
                      )
                    ],
                    1
                  ),
                  _c("div", [_vm._v(_vm._s(_vm.paramsConfig.recordDate))]),
                  _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.changeDate2("after")
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#999" },
                          attrs: { type: "text" }
                        },
                        [
                          _vm._v("后一天"),
                          _c("i", {
                            staticClass: "el-icon-arrow-right el-icon--right"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c("div", { staticStyle: { width: "100%", "padding-top": "20px" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "60%",
                  "margin-left": "20%",
                  display: "flex",
                  "align-items": "center"
                },
                on: {
                  click: function($event) {
                    return _vm.changeList()
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { width: "10px", height: "10px" },
                  attrs: { src: require("../../assets/img/qiehuan.png") }
                }),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      color: "#4C96FF",
                      "font-size": "12px"
                    }
                  },
                  [
                    _vm.changeListType == 1
                      ? _c("span", [_vm._v("切换图片版")])
                      : _c("span", [_vm._v("切换列表版")])
                  ]
                )
              ]
            )
          ]),
          _vm._m(5),
          _vm.changeListType == 1
            ? _c(
                "div",
                { staticStyle: { width: "100%", "padding-bottom": "80px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "60%",
                        "margin-left": "20%",
                        "padding-top": "10px"
                      },
                      on: { scroll: _vm.scrollEvent }
                    },
                    _vm._l(_vm.tableData, function(item, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              padding: "20px 0",
                              "border-bottom": "1px dashed #ccc",
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "space-between",
                                  width: "55%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    item.sequence == 1
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/first.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence == 2
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/second.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence == 3
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/third.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence != 1 &&
                                    item.sequence != 2 &&
                                    item.sequence != 3
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#FFFFFF",
                                              width: "20px",
                                              height: "20px",
                                              background: "#8D9EB9",
                                              "border-radius": "50%",
                                              "font-size": "12px",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(item.sequence))]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-left": "10px",
                                          "font-size": "14px",
                                          "font-weight": "bold"
                                        }
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.name))])]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  _vm._l(item.typeStr, function(item2, index2) {
                                    return _c("div", { key: index2 }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "background-color": "#E3EEFC",
                                            color: "#2489FF",
                                            "font-size": "12px",
                                            "margin-right": "5px",
                                            padding: "5px 10px",
                                            "border-radius": "5px"
                                          }
                                        },
                                        [_c("span", [_vm._v(_vm._s(item2))])]
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pcDetailBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpDetail(item)
                                      }
                                    }
                                  },
                                  [_vm._v(" 详情 ")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pcWatchBtn",
                                    on: {
                                      click: function($event) {
                                        return _vm.jumpLink(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "10px",
                                        height: "11px"
                                      },
                                      attrs: {
                                        src: require("../../assets/img/seeIcon.png")
                                      }
                                    }),
                                    _vm._m(6, true)
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  ),
                  _vm.tableData == ""
                    ? _c(
                        "div",
                        {
                          staticClass: "no-coupons",
                          staticStyle: { overflow: "hidden" }
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              "margin-top": "50px",
                              width: "150px",
                              height: "150px"
                            },
                            attrs: { src: require("../../assets/img/7.png") }
                          }),
                          _c("span", { staticStyle: { "font-size": "15px" } }, [
                            _vm._v("数据正在更新~")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm.changeListType == 2
            ? _c(
                "div",
                { staticStyle: { width: "100%", "padding-bottom": "80px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "60%",
                        "margin-left": "20%",
                        "padding-top": "10px"
                      },
                      on: { scroll: _vm.scrollEvent }
                    },
                    _vm._l(_vm.tableData, function(item, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              padding: "20px 0",
                              "border-bottom": "1px dashed #ccc",
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  width: "100%"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center"
                                    }
                                  },
                                  [
                                    item.sequence == 1
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/first.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence == 2
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/second.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence == 3
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "18px",
                                            height: "25px"
                                          },
                                          attrs: {
                                            src: require("../../assets/img/third.png")
                                          }
                                        })
                                      : _vm._e(),
                                    item.sequence != 1 &&
                                    item.sequence != 2 &&
                                    item.sequence != 3
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#FFFFFF",
                                              width: "20px",
                                              height: "20px",
                                              background: "#8D9EB9",
                                              "border-radius": "50%",
                                              "font-size": "12px",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center"
                                            }
                                          },
                                          [_vm._v(" " + _vm._s(item.sequence))]
                                        )
                                      : _vm._e(),
                                    _c("el-image", {
                                      staticStyle: {
                                        width: "75px",
                                        height: "105px",
                                        "border-radius": "10px",
                                        "margin-left": "10px"
                                      },
                                      attrs: {
                                        fit: "cover",
                                        src: item.poster + "!226"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { width: "calc(100% - 115px)" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "50%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "space-between"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-left": "10px",
                                              "font-size": "14px",
                                              "font-weight": "bold",
                                              width: "calc(100%-500px)"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              width: "150px"
                                            }
                                          },
                                          _vm._l(item.typeStr, function(
                                            item2,
                                            index2
                                          ) {
                                            return _c("div", { key: index2 }, [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#E3EEFC",
                                                    color: "#2489FF",
                                                    "font-size": "12px",
                                                    "margin-right": "5px",
                                                    padding: "5px 10px",
                                                    "border-radius": "5px"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item2))
                                                  ])
                                                ]
                                              )
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "space-between",
                                          padding: "10px 0 0 10px"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "#696F7A",
                                              "font-size": "14px",
                                              height: "40px",
                                              width: "100%",
                                              "align-items": "center",
                                              display: "flex"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.phrase) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pcDetailBtn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.jumpDetail(item)
                                                  }
                                                }
                                              },
                                              [_vm._v(" 详情 ")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pcWatchBtn",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.jumpLink(item)
                                                  }
                                                }
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "10px",
                                                    height: "11px"
                                                  },
                                                  attrs: {
                                                    src: require("../../assets/img/seeIcon.png")
                                                  }
                                                }),
                                                _vm._m(7, true)
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ])
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          color: "#fff",
          "font-size": "16px",
          "font-weight": "bold"
        }
      },
      [_c("span", [_vm._v("短剧日历")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          right: "30px",
          bottom: "42%",
          width: "12%",
          height: "50%"
        }
      },
      [
        _c("img", {
          staticStyle: { width: "100%", height: "100%", display: "block" },
          attrs: { src: require("../../assets/img/1.png") }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "2px" } }, [
      _c("span", [_vm._v("观看")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "2px" } }, [
      _c("span", [_vm._v("观看")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { position: "absolute", top: "0", width: "100%" } },
      [
        _c(
          "div",
          {
            staticStyle: { width: "60%", height: "100%", "margin-left": "20%" }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  color: "#fff",
                  "font-size": "28px",
                  "font-weight": "bold",
                  "padding-top": "15px",
                  "font-family": "MicrosoftYaHei-Bold",
                  display: "flex",
                  "justify-content": "center",
                  width: "100%"
                }
              },
              [_c("span", [_vm._v("短剧日历")])]
            )
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "100%" } }, [
      _c(
        "div",
        {
          staticStyle: {
            width: "60%",
            "margin-left": "20%",
            display: "flex",
            "align-items": "center",
            "justify-content": "space-between",
            color: "#A2B6CB",
            "font-size": "14px",
            "border-bottom": "1px dashed #ccc",
            padding: "20px 0"
          }
        },
        [
          _c("div", [_vm._v("剧名")]),
          _c("div", [_vm._v("题材类型")]),
          _c("div", { staticStyle: { "padding-right": "10px" } }, [
            _vm._v("更多精彩")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "2px" } }, [
      _c("span", [_vm._v("观看")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-left": "2px" } }, [
      _c("span", [_vm._v("观看")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }