import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import "core-js/modules/es.string.trim";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pathToRegexp from "path-to-regexp";
export default {
  name: "Breadcrumb",
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [{
          path: "",
          meta: {
            title: ""
          }
        }].concat(matched);
      } //console.log('matched===',matched)


      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    // 判断是不是仪表页
    isDashboard: function isDashboard(route) {
      var name = route && route.name;

      if (!name) {
        return false;
      }

      return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    },
    pathCompile: function pathCompile(path) {
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    }
  },
  created: function created() {
    this.getBreadcrumb();
  }
};