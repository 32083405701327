var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [_c("span", { staticClass: "sidebar-appname" }, [_vm._v(" 作品系统 ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }