import http from "@/plugins/axios"; // 小程序列表

export function api_app_list(data) {
  return http({
    url: "/app/doc/list",
    method: 'get',
    data: data
  });
} // 小程序列表

export function api_doc_limit_list(data) {
  return http({
    url: "/app/doc/limit/list",
    method: 'get',
    data: data
  });
} // 添加小程序

export function api_app_addOrUpdate(data) {
  return http({
    url: "/app/commit/add",
    method: 'post',
    data: data
  });
} // 修改小程序

export function api_app_updateOrUpdate(data) {
  return http({
    url: "/app/commit/update",
    method: 'post',
    data: data
  });
} // 删除小程序

export function api_app_delete(data) {
  return http({
    url: "/app/delete",
    method: 'delete',
    data: data
  });
} // 商户列表

export function api_app_account_list(data) {
  return http({
    url: "/account/doc/list",
    method: 'get',
    data: data
  });
} // 添加商户

export function api_app_addAccount(data) {
  return http({
    url: "/account/commit/add",
    method: 'post',
    data: data
  });
} // 修改商户

export function api_account_update(data) {
  return http({
    url: "/account/commit/update",
    method: 'post',
    data: data
  });
} // 删除小程序

export function api_account_delete(data) {
  return http({
    url: "/app/account/delete",
    method: 'post',
    data: data
  });
} // 提现账号列表

export function api_doc_account_list(data) {
  return http({
    url: "/sta/distributor/account/doc/list",
    method: 'get',
    data: data
  });
} // 管理员接口：新增

export function api_distributorAccount_save(data) {
  return http({
    url: "/sta/distributor/account/commit/save",
    method: 'post',
    data: data
  });
} // 管理员接口：更新

export function api_distributorAccount_update(data) {
  return http({
    url: "/sta/distributor/account/commit/update",
    method: 'post',
    data: data
  });
} // 分销商提现记录

export function api_distributor_withdrawal_list(data) {
  return http({
    url: "/sta/distributor/account/logs/doc/limit/list",
    method: 'get',
    data: data
  });
} // 分销商提现记录

export function api_distributor_withdrawal_money(data) {
  return http({
    url: "/sta/distributor/account/doc/get/amount",
    method: 'get',
    data: data
  });
} // 分销商创建提现请求

export function api_distributor_withdrawal_save(data) {
  return http({
    url: "/sta/distributor/account/logs/commit/save",
    method: 'post',
    data: data
  });
}