// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.ttf?t=1706093214709");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"iconfont\"; /* Project id 4394169 */\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n.iconfont {\n  font-family: \"iconfont\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-shangpinku1:before {\n  content: \"\\e600\";\n}\n\n.icon-shangpinku:before {\n  content: \"\\e632\";\n}\n\n.icon-wechat:before {\n  content: \"\\e619\";\n}\n\n.icon-douyin:before {\n  content: \"\\e604\";\n}\n\n", ""]);
// Exports
module.exports = exports;
