import "core-js/modules/es.array.for-each";
import _Message2 from "element-ui/lib/message";
import _Message from "element-ui/lib/message";

/**防止重复点击重复弹出message弹框 */
var messageInstance = null;

var resetMessage = function resetMessage(options) {
  if (messageInstance) {
    messageInstance.close();
  }

  messageInstance = _Message(options);
};

['error', 'success', 'info', 'warning'].forEach(function (type) {
  resetMessage[type] = function (options) {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }

    options.type = type;
    return resetMessage(options);
  };
});
export var message = resetMessage;