import _Notification2 from "element-ui/lib/notification";
import _Notification from "element-ui/lib/notification";
import _MessageBox2 from "element-ui/lib/message-box";
import _MessageBox from "element-ui/lib/message-box";
import _Loading2 from "element-ui/lib/loading";
import _Loading from "element-ui/lib/loading";
import _Tooltip2 from "element-ui/lib/tooltip";
import _Tooltip from "element-ui/lib/tooltip";
import _Progress2 from "element-ui/lib/progress";
import _Progress from "element-ui/lib/progress";
import _Divider2 from "element-ui/lib/divider";
import _Divider from "element-ui/lib/divider";
import _Popover2 from "element-ui/lib/popover";
import _Popover from "element-ui/lib/popover";
import _Rate2 from "element-ui/lib/rate";
import _Rate from "element-ui/lib/rate";
import _Menu2 from "element-ui/lib/menu";
import _Menu from "element-ui/lib/menu";
import _Image2 from "element-ui/lib/image";
import _Image from "element-ui/lib/image";
import _Footer2 from "element-ui/lib/footer";
import _Footer from "element-ui/lib/footer";
import _Main2 from "element-ui/lib/main";
import _Main from "element-ui/lib/main";
import _Aside2 from "element-ui/lib/aside";
import _Aside from "element-ui/lib/aside";
import _Header2 from "element-ui/lib/header";
import _Header from "element-ui/lib/header";
import _Container2 from "element-ui/lib/container";
import _Container from "element-ui/lib/container";
import _Upload2 from "element-ui/lib/upload";
import _Upload from "element-ui/lib/upload";
import _Col2 from "element-ui/lib/col";
import _Col from "element-ui/lib/col";
import _Row2 from "element-ui/lib/row";
import _Row from "element-ui/lib/row";
import _Icon2 from "element-ui/lib/icon";
import _Icon from "element-ui/lib/icon";
import _Tree2 from "element-ui/lib/tree";
import _Tree from "element-ui/lib/tree";
import _Tag2 from "element-ui/lib/tag";
import _Tag from "element-ui/lib/tag";
import _Tabs2 from "element-ui/lib/tabs";
import _Tabs from "element-ui/lib/tabs";
import _FormItem2 from "element-ui/lib/form-item";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/form";
import _Form from "element-ui/lib/form";
import _BreadcrumbItem2 from "element-ui/lib/breadcrumb-item";
import _BreadcrumbItem from "element-ui/lib/breadcrumb-item";
import _Breadcrumb2 from "element-ui/lib/breadcrumb";
import _Breadcrumb from "element-ui/lib/breadcrumb";
import _TimePicker2 from "element-ui/lib/time-picker";
import _TimePicker from "element-ui/lib/time-picker";
import _TimeSelect2 from "element-ui/lib/time-select";
import _TimeSelect from "element-ui/lib/time-select";
import _DatePicker2 from "element-ui/lib/date-picker";
import _DatePicker from "element-ui/lib/date-picker";
import _TableColumn2 from "element-ui/lib/table-column";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/table";
import _Table from "element-ui/lib/table";
import _ButtonGroup2 from "element-ui/lib/button-group";
import _ButtonGroup from "element-ui/lib/button-group";
import _Button2 from "element-ui/lib/button";
import _Button from "element-ui/lib/button";
import _OptionGroup2 from "element-ui/lib/option-group";
import _OptionGroup from "element-ui/lib/option-group";
import _Submenu2 from "element-ui/lib/submenu";
import _Submenu from "element-ui/lib/submenu";
import _Option2 from "element-ui/lib/option";
import _Option from "element-ui/lib/option";
import _Select2 from "element-ui/lib/select";
import _Select from "element-ui/lib/select";
import _Switch2 from "element-ui/lib/switch";
import _Switch from "element-ui/lib/switch";
import _TabPane2 from "element-ui/lib/tab-pane";
import _TabPane from "element-ui/lib/tab-pane";
import _CheckboxGroup2 from "element-ui/lib/checkbox-group";
import _CheckboxGroup from "element-ui/lib/checkbox-group";
import _CheckboxButton2 from "element-ui/lib/checkbox-button";
import _CheckboxButton from "element-ui/lib/checkbox-button";
import _Checkbox2 from "element-ui/lib/checkbox";
import _Checkbox from "element-ui/lib/checkbox";
import _RadioButton2 from "element-ui/lib/radio-button";
import _RadioButton from "element-ui/lib/radio-button";
import _RadioGroup2 from "element-ui/lib/radio-group";
import _RadioGroup from "element-ui/lib/radio-group";
import _Radio2 from "element-ui/lib/radio";
import _Radio from "element-ui/lib/radio";
import _InputNumber2 from "element-ui/lib/input-number";
import _InputNumber from "element-ui/lib/input-number";
import _Input2 from "element-ui/lib/input";
import _Input from "element-ui/lib/input";
import _DropdownItem2 from "element-ui/lib/dropdown-item";
import _DropdownItem from "element-ui/lib/dropdown-item";
import _DropdownMenu2 from "element-ui/lib/dropdown-menu";
import _DropdownMenu from "element-ui/lib/dropdown-menu";
import _Dropdown2 from "element-ui/lib/dropdown";
import _Dropdown from "element-ui/lib/dropdown";
import _Pagination2 from "element-ui/lib/pagination";
import _Pagination from "element-ui/lib/pagination";
import _Card2 from "element-ui/lib/card";
import _Card from "element-ui/lib/card";
import _Scrollbar2 from "element-ui/lib/scrollbar";
import _Scrollbar from "element-ui/lib/scrollbar";
import _MenuItem2 from "element-ui/lib/menu-item";
import _MenuItem from "element-ui/lib/menu-item";
import _Dialog2 from "element-ui/lib/dialog";
import _Dialog from "element-ui/lib/dialog";
import _Alert2 from "element-ui/lib/alert";
import _Alert from "element-ui/lib/alert";
import Vue from 'vue';
import { message } from "./message";
Vue.use(_Alert);
Vue.use(_Dialog);
Vue.use(_MenuItem);
Vue.use(_Scrollbar);
Vue.use(_Card);
Vue.use(_Pagination);
Vue.use(_Dropdown);
Vue.use(_DropdownMenu);
Vue.use(_DropdownItem);
Vue.use(_Input);
Vue.use(_InputNumber);
Vue.use(_Radio);
Vue.use(_RadioGroup);
Vue.use(_RadioButton);
Vue.use(_Checkbox);
Vue.use(_CheckboxButton);
Vue.use(_CheckboxGroup);
Vue.use(_TabPane);
Vue.use(_Switch);
Vue.use(_Select);
Vue.use(_Option);
Vue.use(_Submenu);
Vue.use(_OptionGroup);
Vue.use(_Button);
Vue.use(_ButtonGroup);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_DatePicker);
Vue.use(_TimeSelect);
Vue.use(_TimePicker);
Vue.use(_Breadcrumb);
Vue.use(_BreadcrumbItem);
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Tabs);
Vue.use(_Tag);
Vue.use(_Tree);
Vue.use(_Icon);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Upload);
Vue.use(_Container);
Vue.use(_Header);
Vue.use(_Aside);
Vue.use(_Main);
Vue.use(_Footer);
Vue.use(_Image);
Vue.use(_Menu);
Vue.use(_Rate);
Vue.use(_Popover);
Vue.use(_Divider);
Vue.use(_Progress);
Vue.use(_Tooltip);
Vue.use(_Loading.directive);
Vue.prototype.$message = message;
Vue.prototype.$loading = _Loading.service;
Vue.prototype.$alert = _MessageBox.alert;
Vue.prototype.$confirm = _MessageBox.confirm;
Vue.prototype.$prompt = _MessageBox.prompt;
Vue.prototype.$notify = _Notification;