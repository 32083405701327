import http from "@/plugins/axios"; // 登录

export function login(data) {
  return http({
    url: "user/action/login",
    method: 'post',
    data: data
  });
}
export function authorLogin(data) {
  return http({
    url: "/sys/author/doc/login",
    method: 'post',
    data: data
  });
}
export function login2(data) {
  return http({
    url: "/user/action/distributor/login",
    method: 'post',
    data: data
  });
} // 渠道商账号列表

export function api_admin_list(data) {
  return http({
    url: "/sys/user/doc/list",
    method: 'get',
    data: data
  });
} // 添加或修改渠道商账号

export function api_admin_addOrUpdate(data) {
  return http({
    url: "/sys/user/commit/add",
    method: 'post',
    data: data
  });
} // 删除渠道商账号

export function api_admin_delete(data) {
  return http({
    url: "/admin/delete",
    method: 'delete',
    data: data
  });
} // 分销商账号列表

export function api_distribution_list(data) {
  return http({
    url: "/distribution/list",
    method: 'get',
    data: data
  });
} // 添加或修改分销商账号

export function api_distribution_addOrUpdate(data) {
  return http({
    url: "/distribution/addOrUpdate",
    method: 'post',
    data: data
  });
} // 删除分销商账号

export function api_distribution_delete(data) {
  return http({
    url: "/distribution",
    method: 'delete',
    data: data
  });
} // 短剧列表

export function api_distribution_videoList(data) {
  return http({
    url: "/distribution/videoList",
    method: 'get',
    data: data
  });
}
export function api_distribution_apiAccountGet(data) {
  return http({
    url: "/sys/api/account/doc/get",
    method: 'get',
    data: data
  });
}