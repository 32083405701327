"use strict";

import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import _objectSpread from "/home/wwwroot/calendar-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import axios from "axios";
import { getToken } from "@/utils/auth";
import qs from 'qs-stringify';
import { message } from "@/plugins/message";
import store from "@/store";
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"; // 不同环境不同地址

var apis = {
  production: 'https://manager-api.jrweid.com/api',
  // 线上 (生成环境) :  https://www.yanxingwl.cn/api
  //development: 'https://test.weijuhaokam.com/api-admin', // 本地代理请求 (开发环境) :  http://localhost:8081/api
  development: 'http://192.168.3.21:8081/manager-api' //development: 'http://ldworktest.natapp1.cc/api', // 本地代理请求 (开发环境) :  http://localhost:8081/api

}; //console.log(process.env.VUE_APP_outputDir);

var config = {
  baseURL: process.env.VUE_APP_apiUrl,
  timeout: 60 * 1000,
  withCredentials: false
};
var extra = {}; // create an axios instance

var service = axios.create(config); // request interceptor

service.interceptors.request.use(function (config) {
  var token = window.sessionStorage.getItem('token') || store.state.user.token;

  if (token) {
    config.headers.token = token;
  }

  if (config.method === "post") {
    config.data = _objectSpread(_objectSpread({}, extra), config.data);

    switch (config.url) {
      case '/login':
        config.data = qs(config.data);
        break;

      case '/quartz/job/pause':
        config.data = qs(config.data);
        break;

      case '/quartz/job/resume':
        config.data = qs(config.data);
        break;

      case '/quartz/job/update':
        config.data = qs(config.data);
        break;

      case '/quartz/job/delete':
        config.data = qs(config.data);
        break;

      default:
        config.data = JSON.parse(JSON.stringify(config.data));
    }
  } else {
    config.params = _objectSpread(_objectSpread({}, extra), config.params);
  }

  return config;
}, function (error) {
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  console.log('请求接口结果-----------', response.data); // if(response.data.code != 200) message.error(response.data.message)

  if (response.data.code == 10006) {
    var msg = response.data.msg;

    if (msg.indexOf('登录过期') > -1) {
      window.location.hash = '/login';
    }
  } else if (response.data.code == 10007) {
    window.location.hash = '/login';
  } else if (response.data.code == 9999) {
    message.error(response.data.msg);
  }

  return response.data;
}, function (error) {
  console.log('error-->', error);
  message.error(error.info || '服务开了小差，请稍后重试');
  return Promise.reject(error);
}); // 请求封装

var request = function request(val) {
  var data = val.data,
      url = val.url,
      method = val.method; // data.APPID = 'wxf14a7fc9f84e245b';
  //let pid = window.sessionStorage.getItem('pid') || store.state.user.pid;
  // if (data) {
  // 	if (!data.notPid) { // 带全局pid进行传参
  // 		if (!data.pid) {
  // 			data.pid = Number(pid);
  // 		}
  // 	} else { // 不带全局pid，只传自定义数据
  // 		data = data._data_
  // 	}
  // } else {
  // 	data = {
  // 		pid: Number(pid)
  // 	};
  // }

  console.log('请求接口参数-----------', data);

  if (method === 'get' || method === 'GET') {
    // get
    return service({
      url: url,
      method: method,
      params: data
    });
  } else if (method === 'post' || method === 'POST') {
    // post
    return service({
      url: url,
      method: method,
      data: data
    });
  } else {
    // delete & put
    url = url + '/' + data.id;
    data = '';
    return service({
      url: url,
      method: method,
      data: data
    });
  }
};

export default request;