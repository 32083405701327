import Cookies from "js-cookie";

var defaultSettings = require("@/settings.js");

var TokenKey = defaultSettings.title || "vue_admin_template_token";
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}