import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
import shortplay from '@/views/shortplay/index.vue'; // 1: 超级管理员
// 2：渠道用户
// 3: 分销用户

var isAllRs = [1, 2, 3, 4, 50];
var isAmdinRs = [1, 2, 3, 50];
var isUserRs = [1, 2, 3, 50];
var distribution = [2, 50];
var content = [10, 50];
export var constantRoutes = [{
  path: '/shortplay',
  component: function component() {
    return import('@/views/shortplay/index');
  },
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/shortplay2',
  name: 'indexDetail',
  component: function component() {
    return import('@/views/shortplay/indexDetail');
  },
  meta: {
    title: '',
    icon: 'form',
    roles: isAllRs
  },
  hidden: true
} // {
// 	path: '/shortplay2',
// 	component: Layout,
// 	redirect: '/shortplay/indexDetail',
// 	children: [{
// 		path: 'indexDetail',
// 		name: 'shortplay-indexDetail',
// 		component: () => import('@/views/shortplay/indexDetail'),
// 		meta: {
// 			title: '短剧列表',
// 			icon: 'form',
// 			roles: isAmdinRs,
// 			keepAlive:true
// 		}
// 	}]
// },
]; // 解决重复点击导航路由报错

var originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var createRouter = function createRouter() {
  return new Router({
    //mode: 'history',
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;