import router from "./router";
import store from "@/store";
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

import { getToken } from '@/utils/auth'; // get token from cookie

import getPageTitle from '@/utils/get-page-title';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/shortplay']; // no redirect whitelist
// 路由导航守卫

router.beforeEach(function (to, from, next) {
  document.title = getPageTitle(to.meta.title);

  if (to.path === '/shortplay') {
    next();
    NProgress.done();
  } else {
    var token = sessionStorage.getItem('authorToken') || store.state.user.token; //  if (!token) {
    // console.log(token)
    //    // next('/shortplay');
    // next()
    //    NProgress.done();
    //  } else {
    //    if(to.path == '/') {
    //      next({ path: '/shortplay' });
    //      return
    //    }
    //    next()
    //  }

    if (to.path == '/') {
      next({
        path: '/shortplay'
      });
      return;
    } else {
      next();
    }
  }
});
router.afterEach(function () {
  // finish progress bar
  NProgress.done();
});