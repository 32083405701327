import http from "@/plugins/axios"; // 前台短剧详情

export function api_calendar_login(data) {
  return http({
    url: "/front/consuemr/commit/login",
    method: 'post',
    data: data
  });
} // 前台短剧列表

export function api_calendar_rank(data) {
  return http({
    url: "/front/resource/doc/list",
    method: 'get',
    data: data
  });
} // 前台短剧图片列表

export function api_calendar_rank_poster(data) {
  return http({
    url: "/front/resource/doc/list/poster",
    method: 'get',
    data: data
  });
} // 前台短剧详情

export function api_calendar_detail(data) {
  return http({
    url: "/front/resource/doc/detail",
    method: 'get',
    data: data
  });
} // 短剧详情评论列表

export function api_calendar_commentList(data) {
  return http({
    url: "/front/comment/doc/comment/list",
    method: 'get',
    data: data
  });
} // 短剧详情评论评分

export function api_calendar_commentScore(data) {
  return http({
    url: "/front/consuemr/commit/scoring/resource",
    method: 'post',
    data: data
  });
} // 短剧详情评论评分

export function api_calendar_commentAdd(data) {
  return http({
    url: "/front/comment/commit/add",
    method: 'post',
    data: data
  });
} // 短剧详情评论评分

export function api_calendar_myScore(data) {
  return http({
    url: "/front/consumer/doc/my/score",
    method: 'get',
    data: data
  });
} // 短剧评论点赞

export function api_calendar_getLike(data) {
  return http({
    url: "/front/comment/commit/thump/up",
    method: 'post',
    data: data
  });
} // 短剧用户是否想看

export function api_calendar_wantSee(data) {
  return http({
    url: "/front/consuemr/commit/add/like/view",
    method: 'post',
    data: data
  });
}