import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import router from '@/router';
import { api_calendar_rank, api_calendar_rank_poster, api_calendar_detail } from '@/api/calendar';

function getNextDate(date, day) {
  var dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
  return y + "-" + m + "-" + d;
}

;

function replaceCommasWithSlashes(str) {
  return str.replace(/，/g, '/');
}

function replaceCommasWithSlashes2(str) {
  return str.replace(/、/g, '/');
}

export default {
  name: "shortplay",
  components: {},
  props: {},
  data: function data() {
    return {
      value1: '',
      paramsConfig: {
        pageNum: 1,
        pageSize: 10,
        recordDate: '' // name:'',

      },
      total: 0,
      tableData: [],
      endTime: '',
      dialogMy: false,
      detailInfo: '',
      screenWidth: 0,
      isPC: this.isPCNot(),
      changeDate: [],
      lastDate: '',
      changeRank: [{
        id: 1,
        name: '今日短剧',
        status: 1
      }],
      changeListType: 1,
      oldScrollTop: 0,
      //记录上一次滚动结束后的滚动距离
      scrollTop: 0,
      // 记录当前的滚动距离
      observer: null,
      lastPage: 1
    };
  },
  watch: {},
  computed: {},
  created: function created() {
    this.getCalendarRanl();
    var that = this;

    window.onscroll = function () {
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度

      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度

      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight; //滚动条到底部的条件

      if (scrollTop + windowHeight >= scrollHeight / 1.5) {
        //写后台加载数据的函数
        // console.log("距顶部" + scrollTop + "可视区高度" + windowHeight + "滚动条总高度" + scrollHeight);
        if (that.lastPage > that.paramsConfig.pageNum) {
          that.paramsConfig.pageNum += 1;
          console.log(that.changeListType);

          if (that.changeListType == 1) {
            that.getCalendarRanl();
          } else {
            that.getCalendarRanlPoster();
          }
        }
      }
    };
  },
  mounted: function mounted() {
    if (this._isMobile()) {
      // alert("手机端");
      this.isPC = true;
    } else {
      // alert("PC端");
      this.isPC = false;
    }
  },
  methods: {
    _isMobile: function _isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    scrollEvent: function scrollEvent(e) {
      console.log(e);
    },
    isPCNot: function isPCNot() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var flag = true;

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }

      return flag;
    },
    jumpLink: function jumpLink(row) {
      console.log(row);

      if (row.jumpUrl == null) {
        this.$message.error('此网站无法跳转观看');
        return;
      } // this.detailInfo.jumpUrl = 'https://www.baidu.com'


      if (row.jumpUrl.indexOf("http") == -1) {
        this.$message.error('此网站无法跳转观看');
        return;
      }

      window.open(row.jumpUrl, '_blank');
    },
    jumpDetail: function jumpDetail(row) {
      // var token = sessionStorage.getItem("token");
      // console.log('首页token====',sessionStorage.getItem("token"))
      this.$router.push({
        path: '/shortplay2',
        query: {
          id: row.id
        }
      }); // if(token){
      // 	this.$router.push({
      // 		path: '/shortplay2',
      // 		query: {
      // 			id: row.id
      // 		}
      // 	})
      // }else{
      // 	var app_id = 'wx70c24841061a9652'; //公众号的唯一标识
      // 	var redirect_uri = 'https://duan.weijuhaokam.com/#/shortplay2'
      // 	var redirect_uri2 = encodeURIComponent(redirect_uri);
      // 	var state2 = row.id;
      // 	// console.log(redirect_uri2);
      // 	window.location.href =
      // 		`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${app_id}&redirect_uri=${redirect_uri2}&response_type=code&scope=snsapi_base&state=${state2}`
      // }
    },
    getCalendarRanl: function getCalendarRanl() {
      var _this = this;

      api_calendar_rank(this.paramsConfig).then(function (res) {
        if (res.code == 200) {
          var rdata = res.data;
          _this.lastPage = rdata.pages;
          _this.tableData = _this.tableData.concat(rdata.records);

          _this.tableData.forEach(function (item, index) {
            item.sequence = index + 1 < 10 ? '0' + (index + 1) : index + 1;

            if (item.typeStr && typeof item.typeStr == 'string') {
              var typeStr = replaceCommasWithSlashes(item.typeStr);
              item.typeStr = typeStr.split("/");
            }

            if (index == 0) {
              _this.paramsConfig.recordDate = item.recordDate;
              _this.lastDate = item.recordDate;

              _this.changeDate.push({
                date: getNextDate(item.recordDate, -1)
              }, {
                date: item.recordDate
              });
            }
          });

          console.log(_this.tableData);
        } else {
          _this.$message.error(res.msg);
        }
      }).catch(function (err) {//this.btnLoading = false;
      });
    },
    getCalendarRanl2: function getCalendarRanl2() {
      var _this2 = this;

      api_calendar_rank(this.paramsConfig).then(function (res) {
        if (res.code == 200) {
          var rdata = res.data;
          _this2.lastPage = rdata.pages;
          rdata.records.forEach(function (item, index) {
            item.sequence = index + 1 < 10 ? '0' + (index + 1) : index + 1;

            if (item.typeStr) {
              var typeStr = replaceCommasWithSlashes(item.typeStr);
              item.typeStr = typeStr.split("/");
            }
          });
          _this2.tableData = rdata.records; // this.total = rdata.total;
        } else {
          _this2.$message.error(res.msg);
        }
      }).catch(function (err) {//this.btnLoading = false;
      });
    },
    getCalendarRanlPoster: function getCalendarRanlPoster() {
      var _this3 = this;

      api_calendar_rank_poster(this.paramsConfig).then(function (res) {
        if (res.code == 200) {
          var rdata = res.data;
          _this3.lastPage = rdata.pages;
          _this3.tableData = _this3.tableData.concat(rdata.records);

          _this3.tableData.forEach(function (item, index) {
            item.sequence = index + 1;

            if (item.typeStr && typeof item.typeStr == 'string') {
              var typeStr = replaceCommasWithSlashes(item.typeStr);
              item.typeStr = typeStr.split("/");
            }

            if (index == 0) {
              _this3.paramsConfig.recordDate = item.recordDate;
              _this3.lastDate = item.recordDate;

              _this3.changeDate.push({
                date: getNextDate(item.recordDate, -1)
              }, {
                date: item.recordDate
              });
            }
          });

          console.log(_this3.tableData);
        } else {
          _this3.$message.error(res.msg);
        }
      }).catch(function (err) {//this.btnLoading = false;
      });
    },
    getCalendarRanlPoster2: function getCalendarRanlPoster2() {
      var _this4 = this;

      api_calendar_rank_poster(this.paramsConfig).then(function (res) {
        if (res.code == 200) {
          var rdata = res.data;
          _this4.lastPage = rdata.pages;
          rdata.records.forEach(function (item, index) {
            item.sequence = index + 1;

            if (item.typeStr) {
              var typeStr = replaceCommasWithSlashes(item.typeStr);
              item.typeStr = typeStr.split("/");
            }
          });
          _this4.tableData = rdata.records;
        } else {
          _this4.$message.error(res.msg);
        }
      }).catch(function (err) {//this.btnLoading = false;
      });
    },
    getCalendarDetail: function getCalendarDetail(row) {
      var _this5 = this;

      api_calendar_detail({
        id: row.id
      }).then(function (res) {
        if (res.code == 200) {
          var rdata = res.data;
          _this5.detailInfo = rdata;
          console.log(_this5.detailInfo); // this.total = rdata.total;
        } else {
          _this5.$message.error(res.msg);
        }
      }).catch(function (err) {//this.btnLoading = false;
      });
    },
    rowClick: function rowClick(row, column) {
      this.dialogMy = true;
      this.getCalendarDetail(row);
      console.log(row);
    },
    // 分页
    oneSizeChange: function oneSizeChange(val) {
      this.paramsConfig.pageSize = val;
      this.getCalendarRanl();
    },
    oneCurrentChange: function oneCurrentChange(val) {
      this.paramsConfig.pageNum = val;
      this.getCalendarRanl();
    },
    search: function search() {
      this.changeDate = [];
      console.log(this.paramsConfig.recordDate); //this.changeDate = [];

      if (this.paramsConfig.recordDate == this.lastDate) {
        this.changeDate.push({
          date: getNextDate(this.paramsConfig.recordDate, -1)
        }, {
          date: this.paramsConfig.recordDate
        });
      } else {
        this.changeDate.push({
          date: getNextDate(this.paramsConfig.recordDate, -1)
        }, {
          date: this.paramsConfig.recordDate
        }, {
          date: getNextDate(this.paramsConfig.recordDate, +1)
        });
      }

      this.getCalendarRanl2();
    },
    changeDate2: function changeDate2(operation) {
      this.lastPage = 1;

      if (operation == 'before') {
        this.paramsConfig.pageNum = 1;
        this.paramsConfig.recordDate = getNextDate(this.paramsConfig.recordDate, -1);

        if (this.changeListType == 1) {
          this.getCalendarRanl2();
        } else {
          this.getCalendarRanlPoster2();
        }
      } else {
        this.paramsConfig.pageNum = 1;
        this.paramsConfig.recordDate = getNextDate(this.paramsConfig.recordDate, +1);

        if (this.changeListType == 1) {
          this.getCalendarRanl2();
        } else {
          this.getCalendarRanlPoster2();
        }
      }
    },
    // 关闭dialog回调
    closeDialog: function closeDialog(formName) {// if (this.funcType == 1 || this.funcType == 2) {
      // 	this.$refs[formName].clearValidate();
      // }
    },
    changeList: function changeList() {
      if (this.changeListType == 1) {
        this.changeListType = 2;
        this.paramsConfig.pageNum = 1;
        this.paramsConfig.recordDate = '';
        this.tableData = [];
        this.getCalendarRanlPoster();
      } else {
        this.changeListType = 1;
        this.paramsConfig.pageNum = 1;
        this.paramsConfig.recordDate = '';
        this.tableData = [];
        this.getCalendarRanl();
      }
    }
  }
};